(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/flat-array/assets/javascripts/flat-array.js') >= 0) return;  svs.modules.push('/components/lb-utils/flat-array/assets/javascripts/flat-array.js');
"use strict";

const flatArray = function (arrayToBeFlatted) {
  let depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Infinity;
  let flattedArr = [];
  if (Array.prototype.flat) {
    flattedArr = arrayToBeFlatted.flat(depth);
  } else {
    const recursiveFlatting = nextPartOfArrayToBeFlatted => {
      for (let i = 0; i < nextPartOfArrayToBeFlatted.length; ++i) {
        if (nextPartOfArrayToBeFlatted[i] instanceof Array) {
          recursiveFlatting(nextPartOfArrayToBeFlatted[i]);
        } else {
          flattedArr.push(nextPartOfArrayToBeFlatted[i]);
        }
      }
    };
    recursiveFlatting(arrayToBeFlatted);
  }
  return flattedArr;
};
setGlobal('svs.components.lbUtils.flatArray.flatArray', flatArray);

 })(window);